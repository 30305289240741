* {
    margin: 0;
    padding: 0;
}

html,
body {
    width: 100%;
    height: 100%;
}

body {
    background-image: linear-gradient(
        to left,
        rgba(255, 0, 149, 0.2),
        rgba(0, 247, 255, 0.2)
    );
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}

#box {
    /* background-color: red; */
    width: 1200px;
    height: 100px;
    margin: 0 auto;
    padding-top: 5%;
}

.meBox {
    float: left;
    width: 20rem;
    height: 25rem;
    background-color: white;
    margin-top: 100px;
    border-radius: 2%;
    transition: all 0.3s;
    text-align: center;
}

.meBox:hover {
    width: 21rem;
    height: 26rem;
    margin: 95px 0 0 -5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}

.headPhoto {
    width: 8rem;
    height: 8rem;
    background: url(https://static.saintic.com/cdn/images/at.png!/fw/200)
        no-repeat;
    background-size: cover;
    border-radius: 50%;
    position: relative;
    top: -15%;
    left: 50%;
    margin-left: -4rem;
    transition: all 0.3s;
}

.meBox:hover .headPhoto {
    width: 9rem;
    height: 9rem;
    margin: -0.5rem 0 0 -4.5rem;
    transform: rotate(360deg);
}

.headPhoto:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.meBox-title {
    width: auto;
    margin: 0 auto;
}

@keyframes typing {
    from {
        width: 0;
    }
}

@keyframes blink-caret {
    50% {
        border-color: transparent;
    }
}

.meBox-title p {
    font-size: 1.2rem;
    border-right: 0.1em solid;
    width: 12ch;
    white-space: nowrap;
    overflow: hidden;
    animation: typing 2s steps(20, end),
        blink-caret 0.3s step-end infinite alternate;
    margin: -30px 0 0 90px;
    transition: all 0.3s;
}

.meBox:hover .meBox-title p {
    font-size: 1.3rem;
}

.meBox-title .fg {
    width: 80%;
    height: 2px;
    background-image: linear-gradient(to left, #3498db, #2980b9);
    margin: 5% 0 0 10%;
}

.meBox-text {
    width: 80%;
    height: 45%;
    overflow: hidden;
    text-align: center;
    color: rgb(70, 70, 70);
    animation-name: meBox-text;
    animation-duration: 2s;
    animation-fill-mode: forwards;
    margin: 5% 0 0 10%;
    transition: all 0.3s;
}

.meBox-text p {
    margin-top: 5px;
}

.meBox:hover .meBox-text {
    font-size: 1.05rem;
}

@keyframes meBox-text {
    0% {
        transform: translateY(50px);
        color: white;
    }

    100% {
        transform: translateY(0);
        color: rgb(70, 70, 70);
    }
}

.meBox-Button {
    width: 100%;
}

.meBox-Button a {
    display: inline-block;
    float: left;
    width: 158px;
    height: 50px;
    margin-top: 13px;
    line-height: 50px;
    text-decoration: none;
    color: gray;
    transition: all 0.3s;
}

.meBox-Button a:hover {
    color: rgb(0, 132, 255);
    font-size: 1.2rem;
}

.meBox:hover .meBox-Button a {
    width: 165px;
    margin-top: 10px;
}

#cmdBox {
    width: 710px;
    height: 550px;
    float: right;
    /* background-color: green; */
}

.cmd {
    width: 600px;
    height: 400px;
    border-radius: 10px 10px 5px 5px;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.9);
    transition: all 0.3s;
    font-size: 14px;
    color: rgb(88, 89, 92);
    position: relative;
    top: 0;
    left: 0;
}

.cmd:hover {
    width: 610px;
    height: 410px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    margin: -5px 0 0 -5px;
    z-index: 1;
}

.cmd .title {
    width: 100%;
    height: 25px;
    background-image: linear-gradient(to top, rgb(184, 184, 184), white);
    font-size: 14px;
    line-height: 25px;
}

.cmd .title span {
    display: inline-block;
    width: 70%;
    text-align: center;
    /* background-color: red; */
}

.cmd .click {
    margin-left: 10px;
    float: left;
}

.cmd .click div {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 8px 0 0 10px;
    float: left;
}

.cmd .click .red {
    background-color: #ff1b22;
    box-shadow: 0 0 1px #ff1b22;
}

.cmd .click .red:hover {
    background-color: #ff6268;
    box-shadow: 0 0 3px #ff1b22;
}

.cmd .click .yellow {
    background-color: #ffaf00;
    box-shadow: 0 0 1px #ffaf00;
}

.cmd .click .yellow:hover {
    background-color: #ffd373;
    box-shadow: 0 0 3px #ffaf00;
}

.cmd .click .green {
    background-color: #00931a;
    box-shadow: 0 0 1px #00931a;
}

.cmd .click .green:hover {
    background-color: rgb(0, 196, 36);
    box-shadow: 0 0 3px #00931a;
}

.cmdText {
    padding-top: 10px;
    padding-left: 15px;
}

.cmd2 {
    position: relative;
    top: -280px;
    left: 100px;
}

.cmd2 .cmdText .ul {
    margin-left: 15px;
}

.cmd2 .cmdText .ul a {
    text-decoration: none;
    color: rgb(88, 89, 92);
}

.cmd2 .cmdText .ul a:hover {
    color: rgb(0, 132, 255);
}

#footer {
    width: 100%;
    color: white;
    float: left;
    text-align: center;
    position: fixed;
    bottom: 0;
    margin-bottom: 30px;
    /* background-color: blue; */
}

#footer a {
    text-decoration: none;
    color: white;
}

#footer a:hover {
    color: rgb(0, 81, 255);
}

/* 自适应 */
@media screen and (max-width: 1250px) {
    #box {
        width: 100%;
    }

    .meBox {
        margin-left: 3%;
    }

    .meBox:hover {
        width: 21rem;
        height: 26rem;
        margin: 95px 0 0 2.5%;
    }

    #cmdBox {
        margin-right: 3%;
    }
}

@media screen and (max-width: 1120px) {
    .meBox {
        float: none;
        margin: 100px auto 100px;
    }

    .meBox:hover {
        width: 20rem;
        height: 25rem;
        margin: 105px auto 100px;
    }

    .meBox:hover .meBox-Button a {
        width: 158px;
        height: 50px;
        margin-top: 13px;
    }

    .meBox:hover .meBox-title p {
        font-size: 1.2rem;
    }

    .meBox:hover .headPhoto {
        width: 8rem;
        height: 8rem;
        margin: -0.5rem 0 0 -4rem;
        transform: rotate(360deg);
    }

    #cmdBox {
        float: none;
        margin: 0 auto 100px;
    }
}

@media screen and (max-width: 768px) {
    .meBox {
        width: 300px;
    }

    .meBox:hover {
        width: 300px;
        height: 25rem;
        margin: 100px auto 100px;
    }

    .meBox-Button a:hover {
        color: rgb(0, 132, 255);
    }

    .meBox:hover .meBox-Button a {
        width: 150px;
        margin-top: 13px;
    }

    .meBox:hover .meBox-title p {
        font-size: 1.2rem;
    }

    .meBox:hover .headPhoto {
        width: 8rem;
        height: 8rem;
        margin: -0.5rem 0 0 -4rem;
        transform: rotate(360deg);
    }

    .meBox:hover .meBox-text {
        font-size: 1rem;
    }

    .meBox-Button a {
        width: 150px;
    }

    #cmdBox {
        width: 100%;
        height: 850px;
    }

    .cmd {
        width: 90%;
        margin: 0 auto;
    }

    .cmd2 {
        position: relative;
        top: 20px;
        left: 0;
    }

    .cmd:hover {
        width: 90%;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
        margin: 0 auto;
        z-index: 1;
    }
}
