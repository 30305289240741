* {
  margin: 0;
  padding: 0;
}

html, body {
  width: 100%;
  height: 100%;
}

body {
  background-image: linear-gradient(to left, #ff009533, #00f7ff33);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

#box {
  width: 1200px;
  height: 100px;
  margin: 0 auto;
  padding-top: 5%;
}

.meBox {
  float: left;
  text-align: center;
  background-color: #fff;
  border-radius: 2%;
  width: 20rem;
  height: 25rem;
  margin-top: 100px;
  transition: all .3s;
}

.meBox:hover {
  width: 21rem;
  height: 26rem;
  margin: 95px 0 0 -5px;
  box-shadow: 0 0 10px #0006;
}

.headPhoto {
  background: url("https://static.saintic.com/cdn/images/at.png!/fw/200") 0 0 / cover no-repeat;
  border-radius: 50%;
  width: 8rem;
  height: 8rem;
  margin-left: -4rem;
  transition: all .3s;
  position: relative;
  top: -15%;
  left: 50%;
}

.meBox:hover .headPhoto {
  width: 9rem;
  height: 9rem;
  margin: -.5rem 0 0 -4.5rem;
  transform: rotate(360deg);
}

.headPhoto:hover {
  box-shadow: 0 0 10px #0000004d;
}

.meBox-title {
  width: auto;
  margin: 0 auto;
}

@keyframes typing {
  from {
    width: 0;
  }
}

@keyframes blink-caret {
  50% {
    border-color: #0000;
  }
}

.meBox-title p {
  white-space: nowrap;
  border-right: .1em solid;
  width: 12ch;
  margin: -30px 0 0 90px;
  font-size: 1.2rem;
  transition: all .3s;
  animation: 2s steps(20, end) typing, .3s step-end infinite alternate blink-caret;
  overflow: hidden;
}

.meBox:hover .meBox-title p {
  font-size: 1.3rem;
}

.meBox-title .fg {
  background-image: linear-gradient(to left, #3498db, #2980b9);
  width: 80%;
  height: 2px;
  margin: 5% 0 0 10%;
}

.meBox-text {
  text-align: center;
  color: #464646;
  width: 80%;
  height: 45%;
  margin: 5% 0 0 10%;
  transition: all .3s;
  animation-name: meBox-text;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  overflow: hidden;
}

.meBox-text p {
  margin-top: 5px;
}

.meBox:hover .meBox-text {
  font-size: 1.05rem;
}

@keyframes meBox-text {
  0% {
    color: #fff;
    transform: translateY(50px);
  }

  100% {
    color: #464646;
    transform: translateY(0);
  }
}

.meBox-Button {
  width: 100%;
}

.meBox-Button a {
  float: left;
  color: gray;
  width: 158px;
  height: 50px;
  margin-top: 13px;
  line-height: 50px;
  text-decoration: none;
  transition: all .3s;
  display: inline-block;
}

.meBox-Button a:hover {
  color: #0084ff;
  font-size: 1.2rem;
}

.meBox:hover .meBox-Button a {
  width: 165px;
  margin-top: 10px;
}

#cmdBox {
  float: right;
  width: 710px;
  height: 550px;
}

.cmd {
  color: #58595c;
  background-color: #ffffffe6;
  border-radius: 10px 10px 5px 5px;
  width: 600px;
  height: 400px;
  font-size: 14px;
  transition: all .3s;
  position: relative;
  top: 0;
  left: 0;
  overflow: hidden;
}

.cmd:hover {
  z-index: 1;
  width: 610px;
  height: 410px;
  margin: -5px 0 0 -5px;
  box-shadow: 0 0 10px #0006;
}

.cmd .title {
  background-image: linear-gradient(to top, #b8b8b8, #fff);
  width: 100%;
  height: 25px;
  font-size: 14px;
  line-height: 25px;
}

.cmd .title span {
  text-align: center;
  width: 70%;
  display: inline-block;
}

.cmd .click {
  float: left;
  margin-left: 10px;
}

.cmd .click div {
  float: left;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 8px 0 0 10px;
}

.cmd .click .red {
  background-color: #ff1b22;
  box-shadow: 0 0 1px #ff1b22;
}

.cmd .click .red:hover {
  background-color: #ff6268;
  box-shadow: 0 0 3px #ff1b22;
}

.cmd .click .yellow {
  background-color: #ffaf00;
  box-shadow: 0 0 1px #ffaf00;
}

.cmd .click .yellow:hover {
  background-color: #ffd373;
  box-shadow: 0 0 3px #ffaf00;
}

.cmd .click .green {
  background-color: #00931a;
  box-shadow: 0 0 1px #00931a;
}

.cmd .click .green:hover {
  background-color: #00c424;
  box-shadow: 0 0 3px #00931a;
}

.cmdText {
  padding-top: 10px;
  padding-left: 15px;
}

.cmd2 {
  position: relative;
  top: -280px;
  left: 100px;
}

.cmd2 .cmdText .ul {
  margin-left: 15px;
}

.cmd2 .cmdText .ul a {
  color: #58595c;
  text-decoration: none;
}

.cmd2 .cmdText .ul a:hover {
  color: #0084ff;
}

#footer {
  color: #fff;
  float: left;
  text-align: center;
  width: 100%;
  margin-bottom: 30px;
  position: fixed;
  bottom: 0;
}

#footer a {
  color: #fff;
  text-decoration: none;
}

#footer a:hover {
  color: #0051ff;
}

@media screen and (width <= 1250px) {
  #box {
    width: 100%;
  }

  .meBox {
    margin-left: 3%;
  }

  .meBox:hover {
    width: 21rem;
    height: 26rem;
    margin: 95px 0 0 2.5%;
  }

  #cmdBox {
    margin-right: 3%;
  }
}

@media screen and (width <= 1120px) {
  .meBox {
    float: none;
    margin: 100px auto;
  }

  .meBox:hover {
    width: 20rem;
    height: 25rem;
    margin: 105px auto 100px;
  }

  .meBox:hover .meBox-Button a {
    width: 158px;
    height: 50px;
    margin-top: 13px;
  }

  .meBox:hover .meBox-title p {
    font-size: 1.2rem;
  }

  .meBox:hover .headPhoto {
    width: 8rem;
    height: 8rem;
    margin: -.5rem 0 0 -4rem;
    transform: rotate(360deg);
  }

  #cmdBox {
    float: none;
    margin: 0 auto 100px;
  }
}

@media screen and (width <= 768px) {
  .meBox {
    width: 300px;
  }

  .meBox:hover {
    width: 300px;
    height: 25rem;
    margin: 100px auto;
  }

  .meBox-Button a:hover {
    color: #0084ff;
  }

  .meBox:hover .meBox-Button a {
    width: 150px;
    margin-top: 13px;
  }

  .meBox:hover .meBox-title p {
    font-size: 1.2rem;
  }

  .meBox:hover .headPhoto {
    width: 8rem;
    height: 8rem;
    margin: -.5rem 0 0 -4rem;
    transform: rotate(360deg);
  }

  .meBox:hover .meBox-text {
    font-size: 1rem;
  }

  .meBox-Button a {
    width: 150px;
  }

  #cmdBox {
    width: 100%;
    height: 850px;
  }

  .cmd {
    width: 90%;
    margin: 0 auto;
  }

  .cmd2 {
    position: relative;
    top: 20px;
    left: 0;
  }

  .cmd:hover {
    z-index: 1;
    width: 90%;
    margin: 0 auto;
    box-shadow: 0 0 10px #0006;
  }
}
/*# sourceMappingURL=index.b088668c.css.map */
